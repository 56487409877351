import Link from "next/link";
import React, {useEffect} from "react";

const MobileMenu = ({hiddenClass, handleRemove}) => {
    const [height, setHeight] = React.useState("0%");

    useEffect(() => {
        if (hiddenClass === "") {
            setHeight("100%");
        } else {
            setHeight("0%");
        }
    }, [hiddenClass]);
    return (
        <div id="myNav" className="overlay" style={{height}}>
            <a
                className="closebtn"
                onClick={() => {
                    setHeight("0%");
                    handleRemove();
                }}
            >
                &times;
            </a>
            <div className="overlay-content">
                <Link href="/" aria-current="page" onClick={handleRemove}>

                    Home

                </Link>
                <Link href="/over-ons" onClick={handleRemove}>
                    Over Ons
                </Link>
                <Link href="/diensten" onClick={handleRemove}>
                    Diensten
                </Link>
                <Link href="/contact" onClick={handleRemove}>
                    Contact
                </Link>
            </div>
        </div>
    );
};

export default MobileMenu;
