import "../../public/assets/css/animate.min.css";
import React, {useEffect, useState} from "react";
import "../../public/assets/css/tailwind-built.css";
import "react-toastify/dist/ReactToastify.min.css";
import "swiper/css";
import Layout from "../components/layout/Layout";
import {CookieConsent} from "react-cookie-consent";
import {GoogleAnalytics} from "nextjs-google-analytics";

function MyApp({Component, pageProps}) {
    const [allowCookies, setAllowCookies] = useState(false);

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem("cookiesAccepted");
        if (cookiesAccepted === "true") {
            setAllowCookies(true);
        }
    }, []);

    const handleCookies = () => {
        localStorage.setItem("cookiesAccepted", "true");
        setAllowCookies(true);
    }

    return (
        <>
            <Layout>
                <Component {...pageProps} />
                {allowCookies && <GoogleAnalytics trackPageViews strategy="lazyOnload"/>}
                <CookieConsent
                    enableDeclineButton
                    declineButtonText="Weiger cookies"
                    buttonStyle={{
                        background: "#3B82F6",
                        color: "white",
                    }} buttonText="Accepteer cookies" onAccept={handleCookies}>
                    Deze website maakt gebruik van cookies om de gebruikerservaring te verbeteren.
                </CookieConsent>
            </Layout>
        </>
    );
}

export default MyApp;
