import React from "react";
import Link from "next/link";
import Image from "next/image";

const Footer = () => {
    return <>
        <section className="py-20">
            <div
                className="container px-4 mx-auto animate__animated animate__fadeIn"
                data-wow-delay=".1s"
            >
                <div className="flex flex-wrap mb-12 lg:mb-20 -mx-3 text-center lg:text-left">
                    <div className="w-full lg:w-1/5 px-3 mb-6 lg:mb-0">
                        <Link
                            href="/"
                            className="inline-block mx-auto lg:mx-0 text-3xl font-semibold leading-none">

                            <Image
                                src="/assets/imgs/logos/logo.png"
                                alt="Anivia Logo"
                                height="40"
                                width="125"
                            />

                        </Link>
                    </div>
                    <div className="w-full lg:w-2/5 px-3 mb-8 lg:mb-0">
                        <p className="max-w-md mx-auto lg:max-w-full lg:mx-0 lg:pr-32 lg:text-lg text-blueGray-400 leading-relaxed">
                            Onze software is <strong>geen</strong> belofte, het is een{" "}
                            <strong className="text-blue-500">garantie</strong>!
                        </p>
                    </div>
                    <div className="w-full lg:w-1/5 px-3 mb-8 lg:mb-0">
                        <p className="mb-2 lg:mb-4 lg:text-lg font-bold font-heading text-blueGray-800">
                            Locatie
                        </p>
                        <a
                            target="_blank"
                            href="https://www.google.be/maps/place/3550+Heusden-Zolder/@51.0239745,5.2530966,13z/data=!3m1!4b1!4m5!3m4!1s0x47c12462111722f5:0xbdbe930df492d0c5!8m2!3d51.0338027!4d5.2827309"
                        >
                            <p className="lg:text-lg text-blueGray-400">
                                3550 Heusden-Zolder
                            </p>
                            <p className="lg:text-lg text-blueGray-400">België</p>
                        </a>
                    </div>
                    <div className="w-full lg:w-1/5 px-3">
                        <p className="mb-2 lg:mb-4 lg:text-lg font-bold font-heading text-blueGray-800">
                            Contact
                        </p>
                        <p className="lg:text-lg text-blueGray-400">
                            <a href="mailto:info@anivia.be">info@anivia.be</a>
                        </p>
                        <p className="lg:text-lg text-blueGray-400">
                            <a
                                href="https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer=0788.294.848&actionLu=Zoek"
                                target="_blank"
                            >
                                BE 0788.294.848
                            </a>
                        </p>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row items-center lg:justify-between">
                    <p className="text-sm text-blueGray-400">
                        &copy; 2022. All rights reserved by ANIVIA
                    </p>
                    <Link href="/privacy" legacyBehavior>
                        <p className="text-sm text-blueGray-400">
                            Privacy beleid
                        </p>
                    </Link>
                    <Link href="/cookie" legacyBehavior>
                        <p className="text-sm text-blueGray-400">
                            Cookie beleid
                        </p>
                    </Link>
                    <Link href="/terms" legacyBehavior>
                        <p className="text-sm text-blueGray-400">
                            Algemene voorwaarden
                        </p>
                    </Link>
                    <div className="order-first lg:order-last mb-4 lg:mb-0">
                        <a
                            className="inline-block mr-1"
                            href="https://www.linkedin.com/company/anivia-it-solutions/"
                            target="_blank"
                        >
                            <Image
                                src="/assets/imgs/icons/linkedin.svg"
                                alt="LinkedIn Logo"
                                height="36"
                                width="36"
                            />
                        </a>
                        <a
                            className="inline-block ml-1"
                            href="https://www.instagram.com/anivia.be/"
                            target="_blank"
                        >
                            <Image
                                src="/assets/imgs/icons/instagram.svg"
                                alt="Instagram Logo"
                                height="36"
                                width="36"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </>;
};

export default Footer;
