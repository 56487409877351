import Head from "next/head";
import React, {useState} from "react";
import BackToTop from "../elements/BackToTop";
import Footer from "./Footer";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import {ToastContainer} from "react-toastify";
import Script from "next/script";

const Layout = ({children}) => {
    const [hiddenClass, setHiddenClass] = useState("hidden");

    const handleHidden = () => setHiddenClass("");

    const handleRemove = () => {
        if (hiddenClass === "") {
            setHiddenClass("hidden");
        }
    };

    return (
        <>
            <Head>
                <Script src="/assets/js/wow.min.js"/>
                <Script>new WOW().init();</Script>
            </Head>
            <div className="main font-body text-body">
                <ToastContainer position="top-right"/>
                <Header handleHidden={handleHidden}/>
                <MobileMenu hiddenClass={hiddenClass} handleRemove={handleRemove}/>
                {children}
                <Footer/>
                <BackToTop/>
            </div>
        </>
    );
};

export default Layout;
